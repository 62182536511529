const styles = {
  logo: {
    height: 75,
    width: 75,
    marginRight: 5,
    display: { xs: 'none', md: 'flex' },
  },
  appBar: {
    position: 'static',
    backgroundColor: '#0A3D62', // Deep Blue
  },
  toolbar: {
    disableGutters: true,
  },
  logoIcon: {
    display: { xs: 'none', md: 'flex' },
    mr: 1,
    color: '#F5F5F5', // Soft White
  },
  logoTextLarge: {
    mr: 2,
    display: { xs: 'none', md: 'flex' },
    fontFamily: 'Roboto, Lato, sans-serif', // Primary Font
    fontWeight: 700,
    letterSpacing: '.3rem',
    color: '#F5F5F5', // Soft White
    textDecoration: 'none',
  },
  logoTextSmall: {
    mr: 2,
    display: { xs: 'flex', md: 'none' },
    flexGrow: 1,
    fontFamily: 'Roboto, Lato, sans-serif', // Primary Font
    fontWeight: 700,
    letterSpacing: '.3rem',
    color: '#F5F5F5', // Soft White
    textDecoration: 'none',
  },
  menuButtonBox: {
    flexGrow: 1,
    display: { xs: 'flex', md: 'none' },
  },
  menuItemText: {
    textAlign: 'center',
    fontFamily: 'Open Sans, Montserrat, sans-serif', // Secondary Font
    color: '#2E3A45', // Dark Charcoal
  },
  desktopMenuBox: {
    flexGrow: 1,
    display: { xs: 'none', md: 'flex' },
  },
  menuButton: {
    my: 2,
    color: '#F5F5F5', // Soft White
    display: 'block',
    fontFamily: 'Open Sans, Montserrat, sans-serif', // Secondary Font,
    maxWidth: '200px', // Set maximum width
    backgroundColor: '#0A3D62', // Deep Blue
    '&:hover': {
      backgroundColor: '#17A2B8', // Teal on hover
      color: '#F5F5F5', // Soft White text color on hover
    },
  },
  eMenu: {
    display: { xs: 'block', md: 'none' },
  },
};

export default styles;