import React from 'react';
import './SignIn.css'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import awsmobile from '../../aws-exports';
import Cookies from 'js-cookie'; // Import js-cookie

const organizations = [
  { name: "Trillium Health Partners", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTk-7KGXhR40hKpdsnjBm22nVuDOGyoe_ys0Q&s", identity_provider: 'thp' },
  { name: "C-Encode", logo: "https://www.c-encode.com/c-encode.png", identity_provider: 'c-encode' },
];

const OrganizationItem = ({ name, logo, onClick }) => (
  <div className="organization-item" onClick={onClick}>
    <img src={logo} alt={`${name} logo`} className="org-logo" />
    <span>{name}</span>
  </div>
);


function SignIn() {
    const getSAMLURL = (idp) => {
        return `https://${awsmobile.oauth.domain}/oauth2/authorize?identity_provider=${idp}&client_id=${awsmobile.aws_user_pools_web_client_id}&response_type=code&scope=email+openid+phone+aws.cognito.signin.user.admin&redirect_uri=${encodeURIComponent(
            window.location.origin
        )}`
    }

    const setShortTermCookie = (name, value, minutes) => {
        Cookies.set(name, value, { expires: minutes / (24 * 60) }); // Set cookie with expiration in minutes
    }

    return (
    <Card className="sign-in-card">
        <CardContent>
            <Typography variant="h4" className="patient-name" sx={{mt: 2, mb: 2}}>
              Sign In
            </Typography>
            <Typography variant="body1" sx={{mt: 2, mb: 2}}>Please select your organization to log in</Typography>
            <div className="organization-list">
                {organizations.map((org, index) => (
                    <OrganizationItem 
                        key={index} 
                        name={org.name} 
                        logo={org.logo} 
                        onClick={() => {
                            setShortTermCookie('authRedirect', window.location.href, 5); // Set a 5-minute cookie
                            window.location.href = getSAMLURL(org.identity_provider);
                        }}
                    />
                ))}
            </div>
        </CardContent>
    </Card>
    );
}

export default SignIn;
