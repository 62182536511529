// src/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0A3D62', // Deep Blue
    },
    secondary: {
      main: '#17A2B8', // Teal
    },
    text: {
      primary: '#2E3A45', // Dark Charcoal
      secondary: '#B0BEC5', // Cool Gray
    },
    background: {
      default: '#F5F5F5', // Soft White
    },
    action: {
      hover: '#17A2B8', // Teal on hover
    },
  },
  typography: {
    fontFamily: 'Roboto, Lato, sans-serif', // Primary Font
    h1: {
      fontFamily: 'Roboto, Lato, sans-serif',
      fontWeight: 700,
      color: '#0A3D62', // Deep Blue
    },
    h2: {
      fontFamily: 'Roboto, Lato, sans-serif',
      fontWeight: 700,
      color: '#0A3D62', // Deep Blue
    },
    h3: {
      fontFamily: 'Roboto, Lato, sans-serif',
      fontWeight: 700,
      color: '#0A3D62', // Deep Blue
    },
    h4: {
      fontFamily: 'Roboto, Lato, sans-serif',
      fontWeight: 700,
      color: '#0A3D62', // Deep Blue
    },
    h5: {
      fontFamily: 'Roboto, Lato, sans-serif',
      fontWeight: 700,
      color: '#0A3D62', // Deep Blue
    },
    h6: {
      fontFamily: 'Roboto, Lato, sans-serif',
      fontWeight: 700,
      color: '#0A3D62', // Deep Blue
    },
    body1: {
      fontFamily: 'Open Sans, Montserrat, sans-serif', // Secondary Font
      color: '#2E3A45', // Dark Charcoal
    },
    body2: {
      fontFamily: 'Open Sans, Montserrat, sans-serif', // Secondary Font
      color: '#2E3A45', // Dark Charcoal
    },
    button: {
      fontFamily: 'Open Sans, Montserrat, sans-serif', // Secondary Font
      textTransform: 'none', // Keeps button text in normal case
      color: '#F5F5F5', // Soft White
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#17A2B8', // Teal on hover
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#F5F5F5', // Soft White
          '&:hover': {
            color: '#17A2B8', // Teal on hover
          },
        },
      },
    },
  },
});

export default theme;