import React from 'react';

const ExplanationReport = ({ selectedReport }) => {
  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Explanation Report</h2>
      <div style={styles.section}>
        <h3 style={styles.subHeader}>History</h3>
        <p style={styles.text}>{selectedReport["History"]}</p>
      </div>
      <div style={styles.section}>
        <h3 style={styles.subHeader}>Reference / Technique</h3>
        <p style={styles.text}>{selectedReport["Reference / Technique:"]}</p>
      </div>
      <div style={styles.section}>
        <h3 style={styles.subHeader}>BMD Values</h3>
        <p style={styles.text}>{selectedReport["BMD Values"]}</p>
      </div>
      <div style={styles.section}>
        <h3 style={styles.subHeader}>Diagnostic Category</h3>
        <p style={styles.text}>{selectedReport["Diagnostic Category"]}</p>
      </div>
      <div style={styles.section}>
        <h3 style={styles.subHeader}>Fracture Risk</h3>
        <p style={styles.text}>{selectedReport["Fracture Risk"]}</p>
      </div>
      <div style={styles.section}>
        <h3 style={styles.subHeader}>Summary</h3>
        <p style={styles.text}>{selectedReport["Summary"]}</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "'Arial', sans-serif",
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  section: {
    marginBottom: '20px',
    padding: '10px',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  subHeader: {
    color: '#0056b3',
    marginBottom: '10px',
  },
  text: {
    whiteSpace: 'pre-line',
    color: '#333',
  },
};

export default ExplanationReport;