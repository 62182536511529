export const drugOptions = [
  { value: 'Unknown', label: 'Does not know' },
  { value: 'Alendronate', label: 'Alendronate' },
  { value: 'Fosamax', label: 'Fosamax' },
  { value: 'Fosavance', label: 'Fosavance' },
  { value: 'Binosto', label: 'Binosto' },
  { value: 'Etidronate', label: 'Etidronate' },
  { value: 'Didrocal', label: 'Didrocal' },
  { value: 'Didronel', label: 'Didronel' },
  { value: 'Risedronate', label: 'Risedronate' },
  { value: 'Actonel', label: 'Actonel' },
  { value: 'Atelvia', label: 'Atelvia' },
  { value: 'Zoledronic Acid', label: 'Zoledronic Acid' },
  { value: 'Aclasta', label: 'Aclasta' },
  { value: 'Reclast', label: 'Reclast' },
  { value: 'Zometa', label: 'Zometa' },
  { value: 'Denosumab', label: 'Denosumab' },
  { value: 'Prolia (injection)', label: 'Prolia (injection)' },
  { value: 'Teriparatide', label: 'Teriparatide' },
  { value: 'Furteo', label: 'Furteo' },
  { value: 'Pamidronate', label: 'Pamidronate' },
  { value: 'Aredia', label: 'Aredia' },
  { value: 'Ibandronate', label: 'Ibandronate' },
  { value: 'Boniva', label: 'Boniva' },
  { value: 'Nevidronate', label: 'Nevidronate' },
  { value: 'Nirixia', label: 'Nirixia' },
  { value: 'SERMS', label: 'SERMS' },
  { value: 'Raloxifene/EVISAT', label: 'Raloxifene/EVISAT' },
  { value: 'Parathyroid Hormone (PTH)', label: 'Parathyroid Hormone (PTH)' },
  { value: 'Teriparatide (Injection)/Forteo', label: 'Teriparatide (Injection)/Forteo' },
  { value: 'Tilydronate', label: 'Tilydronate' },
  { value: 'Skelid', label: 'Skelid' },
  { value: 'Romosozumab', label: 'Romosozumab' },
  { value: 'Evenity', label: 'Evenity' },
  { value: 'Other', label: 'Other' },
];

export const fractureLocationOptions = [
  { value: '', label: 'Select' },
  { value: 'Forearm', label: 'Forearm' },
  { value: 'Spine', label: 'Vertebra (Spine)' },
  { value: 'Hip', label: 'Proximal Femur (Hip)' },
  { value: 'Shoulder', label: 'Proximal Humerus (Shoulder)' },
  { value: 'Craniofacial', label: 'Craniofacial (Head/Face)' },
  { value: 'Hand', label: 'Hand' },
  { value: 'Foot', label: 'Foot' },
  { value: 'Wrist', label: 'Wrist' },
  { value: 'Pelvis', label: 'Pelvis' },
  { value: 'Other', label: 'Other (Specify)' },
];

export const fractureCauseOptions = [
  { value: '', label: 'Select' },
  { value: 'Fragility', label: 'Fragility (Fall from standing or sitting height)' },
  { value: 'Trauma', label: 'High-impact trauma (e.g., car accident, fall from height)' },
  { value: 'Sports', label: 'Sports Injury' },
  { value: 'Other', label: 'Other (Specify)' },
];