/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_tc5j7zEv4",
    "aws_user_pools_web_client_id": "kuls0872bqr3p7gcbqs6bi6l2",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "oauth": {
        "domain": "c-encode.auth.ca-central-1.amazoncognito.com",
        "scope": ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
        "redirectSignIn": window.location.origin,
        "redirectSignOut": window.location.origin,
        "responseType": 'code'
    },
};


export default awsmobile;
