import React, { useState, useEffect } from 'react';
import { Button, TextField, Card, CardContent, Typography, Modal, Box } from '@mui/material';
import { useSnackbar } from '../../SnackbarContext';
import { apiRequest } from '../../util';
import { useUserRole } from '../../Contexts/UserRoleContext';
import './ConfigurationPage.css'

const Configuration = () => {
  const { showSnackbar } = useSnackbar();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [versions, setVersions] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const [formMode, setFormMode] = useState('add'); // 'add' or 'edit'
  const [orgData, setOrgData] = useState({
    name: '',
    address: {
      line: [''],
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    aliases: [''],
    lsc: {
      lumbarSpine: '',
      totalHip: '',
      forearm: '',
      totalBody: '',
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { role } = useUserRole();

  useEffect(() => {
    if (role === 'Admin') {
      setIsAuthenticated(true);
    }
    fetchVersions();
    fetchOrganizations();
    // eslint-disable-next-line
  }, [role]);

  const fetchVersions = async () => {
      try {
        const response = await apiRequest('/api/configuration/version');
        const data = await response.json();
        if (response.ok) {
          setVersions(data);
        } else {
          showSnackbar(response.message || 'Failed to fetch version information', 'error');
        }
      } catch (error) {
        showSnackbar('Error fetching version information', 'error');
      }
  };

  const fetchOrganizations = async () => {
    try {
      const response = await apiRequest('/api/configuration/organizations');
      const data = await response.json();
      if (response.ok) {
        setOrganizations(data);
      } else {
        showSnackbar('Failed to fetch organizations', 'error');
      }
    } catch (error) {
      showSnackbar('Error fetching organizations', 'error');
    }
  };

  const handleExtractLogs = async () => {
    try {
      const response = await apiRequest('/api/configuration/logs');
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'logs.txt';
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        showSnackbar('Failed to extract logs', 'error');
      }
    } catch (error) {
      showSnackbar('Error extracting logs', 'error');
    }
  };

  const handleAuditLogs = async () => {
    try {
      const response = await apiRequest('/api/configuration/audit-logs');
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'audit-logs.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        showSnackbar('Failed to download audit logs', 'error');
      }
    } catch (error) {
      showSnackbar('Error downloading audit logs', 'error');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrgData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setOrgData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        [name]: value,
      },
    }));
  };

  const handleAliasChange = (index, value) => {
    setOrgData((prevData) => {
      const newAliases = [...prevData.aliases];
      newAliases[index] = value;
      return {
        ...prevData,
        aliases: newAliases,
      };
    });
  };

  const addAliasField = () => {
    setOrgData((prevData) => ({
      ...prevData,
      aliases: [...prevData.aliases, ''],
    }));
  };

  const handleLscChange = (e) => {
    const { name, value } = e.target;
    setOrgData((prevData) => ({
      ...prevData,
      lsc: {
        ...prevData.lsc,
        [name]: value,
      },
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formMode === 'add') {
      await handleAddOrganization();
    } else {
      await handleUpdateOrganization();
    }
    handleCloseModal(); // Close modal after form submission
  };

  const handleAddOrganization = async () => {
    try {
      const response = await apiRequest('/api/configuration/organizations', {
        method: 'POST',
        body: JSON.stringify(orgData),
      });
      if (response.ok) {
        showSnackbar('Organization added successfully', 'success');
        fetchOrganizations();
        setOrgData({
          name: '',
          address: {
            line: [''],
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          aliases: [''],
          lsc: {
            lumbarSpine: '',
            totalHip: '',
            forearm: '',
            totalBody: '',
          },
        });
      } else {
        showSnackbar('Failed to add organization', 'error');
      }
    } catch (error) {
      showSnackbar('Error adding organization', 'error');
    }
  };

  const handleUpdateOrganization = async () => {
    try {
      const response = await apiRequest(`/api/configuration/organizations/${orgData.id}`, {
        method: 'PUT',
        body: JSON.stringify(orgData),
      });
      if (response.ok) {
        showSnackbar('Organization updated successfully', 'success');
        fetchOrganizations();
        setOrgData({
          name: '',
          address: {
            line: [''],
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          aliases: [''],
          lsc: {
            lumbarSpine: '',
            totalHip: '',
            forearm: '',
            totalBody: '',
          },
        });
        setFormMode('add');
      } else {
        showSnackbar('Failed to update organization', 'error');
      }
    } catch (error) {
      showSnackbar('Error updating organization', 'error');
    }
  };

  const handleEditOrganization = (org) => {
    setOrgData({
      ...org,
      address: {
        ...org.contact[0].address,
      },
      aliases: org.alias || [],
      lsc: {
        lumbarSpine: org.extension?.find(ext => ext.url === "http://example.org/fhir/StructureDefinition/lsc-lumbar-spine")?.valueString || '',
        totalHip: org.extension?.find(ext => ext.url === "http://example.org/fhir/StructureDefinition/lsc-total-hip")?.valueString || '',
        forearm: org.extension?.find(ext => ext.url === "http://example.org/fhir/StructureDefinition/lsc-forearm")?.valueString || '',
        totalBody: org.extension?.find(ext => ext.url === "http://example.org/fhir/StructureDefinition/lsc-total-body")?.valueString || '',
      },
    });
    setFormMode('edit');
    handleOpenModal(); // Open modal for editing
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <div className='configurationPage'>
        <h3>Configuration</h3>
        <Card className='infoCard'>
          <CardContent>
            <h4>Version</h4>
            <Typography variant="body2" component="div" sx={{mb: 1}}>
              Frontend Version: {versions?.frontend_version}
            </Typography>
            <Typography variant="body2" component="div" sx={{mb: 1}}>
              Backend Version: {versions?.backend_version}
            </Typography>
            <Typography variant="body2" component="div" sx={{mb: 1}}>
              Flow Version: {versions?.flow_version}
            </Typography>
            <h4>Organizations</h4>
              {organizations.map((org, index) => (
                <Typography key={index} variant="body2" component="div" sx={{mb: 1}}>
                  {org.name}
                  {org.extension?.find(ext => ext.url === "http://example.org/fhir/StructureDefinition/license-expiry-date")?.valueDate 
                    ? ` - (${org.extension.find(ext => ext.url === "http://example.org/fhir/StructureDefinition/license-expiry-date").valueDate})`
                    : ''}
                  {isAuthenticated && 
                  <div onClick={() => handleEditOrganization(org)}>Edit</div>}
                </Typography>
              ))}
            <Button variant="contained" color="primary" onClick={handleExtractLogs} sx={{mb: 2}}>
              Download Application Logs
            </Button>
            <Button variant="contained" color="primary" onClick={handleAuditLogs}>
              Download Audit Logs
            </Button>
            {isAuthenticated && (
              <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{mt: 2}}>
                {formMode === 'add' ? 'Add New Organization' : 'Edit Organization'}
              </Button>
            )}
          </CardContent>
        </Card>
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="organization-modal-title"
          aria-describedby="organization-modal-description"
        >
          <Box sx={{  
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            maxHeight: 500,
            overflowY: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4
          }}>
            <h4 id="organization-modal-title">{formMode === 'add' ? 'Add New Organization' : 'Edit Organization'}</h4>
            <form onSubmit={handleFormSubmit}>
              <TextField
                label="Name"
                name="name"
                value={orgData.name}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Address Line"
                name="line"
                value={orgData.address.line[0]}
                onChange={(e) => handleAddressChange({ target: { name: 'line', value: [e.target.value] } })}
                required
              />
              <TextField
                label="City"
                name="city"
                value={orgData.address.city}
                onChange={handleAddressChange}
                required
              />
              <TextField
                label="State"
                name="state"
                value={orgData.address.state}
                onChange={handleAddressChange}
                required
              />
              <TextField
                label="Postal Code"
                name="postalCode"
                value={orgData.address.postalCode}
                onChange={handleAddressChange}
                required
              />
              <TextField
                label="Country"
                name="country"
                value={orgData.address.country}
                onChange={handleAddressChange}
                required
              />
              {orgData.aliases?.map((alias, index) => (
                <TextField
                  key={index}
                  label={`Alias ${index + 1}`}
                  value={alias}
                  onChange={(e) => handleAliasChange(index, e.target.value)}
                />
              ))}
              <Button onClick={addAliasField} variant="outlined" color="primary">
                Add Alias
              </Button>
              <TextField
                label="LSC Lumbar Spine"
                name="lumbarSpine"
                value={orgData.lsc.lumbarSpine}
                onChange={handleLscChange}
              />
              <TextField
                label="LSC Total Hip"
                name="totalHip"
                value={orgData.lsc.totalHip}
                onChange={handleLscChange}
              />
              <TextField
                label="LSC Forearm"
                name="forearm"
                value={orgData.lsc.forearm}
                onChange={handleLscChange}
              />
              <TextField
                label="LSC Total Body"
                name="totalBody"
                value={orgData.lsc.totalBody}
                onChange={handleLscChange}
              />
              <Button type="submit" variant="contained" color="primary">
                {formMode === 'add' ? 'Add Organization' : 'Update Organization'}
              </Button>
              <Button onClick={() => { setOrgData({ name: '', address: { line: [''], city: '', state: '', postalCode: '', country: '' }, aliases: [''], lsc: {
            lumbarSpine: '',
            totalHip: '',
            forearm: '',
            totalBody: '',
          },  }); setFormMode('add'); handleCloseModal(); }} variant="outlined" color="secondary">
                Cancel
              </Button>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Configuration;