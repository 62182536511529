// src/components/BasePage.js

import React from 'react';
import NavBar from './NavBar';
import { Container } from '@mui/material';

const BasePage = ({ children }) => {
  return (
    <div>
      <NavBar />
      <Container maxWidth="lg">
        {children}
      </Container>
    </div>
  );
};

export default BasePage;