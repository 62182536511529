import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { getRole } from '../util';
import { Auth } from 'aws-amplify';

const UserRoleContext = createContext();

export const UserRoleProvider = ({ children }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [role, setRole] = useState('');

  useEffect(() => {
    if (user) {
      Auth.currentUserInfo();
      const role = getRole(user);
      setRole(role);
    }
  }, [user]);

  return (
    <UserRoleContext.Provider value={{ role, user, signOut }}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRole = () => {
  return useContext(UserRoleContext);
};
