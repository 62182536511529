import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { useSnackbar } from '../../SnackbarContext';
import { apiRequest } from '../../util';
import './Home.css';
import Cookies from 'js-cookie';
import { useUserRole } from '../../Contexts/UserRoleContext';

const HomePage = () => {
  const { user, role} = useUserRole();
  const [searchQueryName, setSearchQueryName] = useState('');
  const [searchQueryId, setSearchQueryId] = useState('');
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [licenseExpired, setLicenseExpired] = useState(false);
  const { showSnackbar } = useSnackbar();
  const checkLicenseExpiry = async () => {
      try {
        const response = await apiRequest('/api/configuration/license');
        if (response.status === 200) {
          const { license_expiry_date: expiryDate } = await response.json();
          const currentDate = new Date();
          const expiry = new Date(expiryDate);
          const timeDiff = expiry - currentDate;
          const daysToExpiry = Math.round(timeDiff / (1000 * 60 * 60 * 24));
          if (daysToExpiry <= 0) {
            setLicenseExpired(true);
            showSnackbar('Your license has expired. Please renew to continue using the service.', 'error', false);
          } else if (daysToExpiry <= 90) {
            showSnackbar(`Your license will expire in ${daysToExpiry} days. To avoid service interruptions, please contact support@c-encode.com.`, 'warning', false);
          }
        } else {
          console.error('Unable to retrieve the license expiry date. Please try again later.');
        }
      } catch (error) {
        console.error('Error fetching license expiry date:', error);
      }
  };

  useEffect(() => {
    checkLicenseExpiry();
    // eslint-disable-next-line
  }, []);

  const handleSearch = async () => {
    if (isSearchDisabled) return;

    try {
      setIsLoading(true);

      // Build the query string based on which fields are filled out
      const queryParams = [];
      if (searchQueryName) queryParams.push(`name=${searchQueryName}`);
      if (searchQueryId) queryParams.push(`id=${searchQueryId}`);

      const queryString = queryParams.join('&');
      const response = await apiRequest(`/api/patient/search?${queryString}`);
      
      if (response.status === 200) {
        const data = await response.json();
        setPatients(data);
      } else {
        showSnackbar('Failed to fetch patients.', 'error');
        setPatients([]);
      }
      
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching patients:', error);
      setIsLoading(false);
    }
  };

  const isSearchDisabled = !searchQueryName && !(searchQueryId && searchQueryId.length > 3);

  useEffect(() => {
      const authRedirect = Cookies.get('authRedirect');
      if (authRedirect) {
          window.location.href = authRedirect;
          Cookies.remove('authRedirect');
      }
  }, []);

  return (
    <>
    {!licenseExpired &&
    <div className="App">
      <div style={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
      <Typography variant="h6">
        Welcome {user.attributes?.name || user.attributes?.email}
      </Typography>
      <Typography variant="body2" sx={{ml: 1}}>
        ({role})
      </Typography>
      </div>
      <div className="search-bar">
        <div className="search-row">
          <TextField
            label="Patient Name"
            variant="outlined"
            value={searchQueryName}
            onChange={(e) => setSearchQueryName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(); // Trigger search on Enter key press
              }
            }}
            fullWidth
          />
        </div>
        <div className="search-row">
          <TextField
            label="Patient ID"
            variant="outlined"
            value={searchQueryId}
            onChange={(e) => setSearchQueryId(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(); // Trigger search on Enter key press
              }
            }}
            fullWidth
          />
        </div>
        <div className="search-actions">
          <Button 
            variant="contained" 
            onClick={handleSearch}
            disabled={isSearchDisabled}
          >
            Search
          </Button>
        </div>
      </div>
        {isLoading ? 
          <CircularProgress className="loading-spinner" /> :
        patients.length === 0 ? 
          <Typography variant="h6">No patients found, please use the search.</Typography> :
          <Grid container spacing={3} className="grid-container">
        {patients?.map((patient) => (
            <Grid item xs={12} key={patient.id}>
              <a href={`/patient/${patient.id}`} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <Card className="card">
                  <CardContent>
                    <Typography variant="h6">{patient.name}</Typography>
                    <Typography variant="body1">Patient ID: {patient.mrn}</Typography>
                    <Typography variant="body1">Sex: {patient.gender}</Typography>
                    <Typography variant="body1">Birthdate: {patient.birth_date}</Typography>
                  </CardContent>
                </Card>
              </a>
          </Grid>
        ))
        }</Grid>
        }
    </div>
    }
    </>
  );
};

export default HomePage;
