import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import './HistoryForm.css';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../SnackbarContext';
import { apiRequest, getFormFields, calculateDurationMonths } from '../../util'; 
import RenderField from '../../Components/RenderField';

const HistoryForm = ({ prefilledData, viewOnly=false }) => {
  const [patient, setPatient] = useState({});
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  let formFields = getFormFields(viewOnly)
  formFields = Object.fromEntries(
    Object.entries(formFields).map(([sectionKey, fields]) => [
      sectionKey,
      fields.filter(field => field.showOnHistoryPage)
    ])
  );

  useEffect(() => {
    if (prefilledData) {
      setPatient((prev) => ({
        ...prev,
        ...prefilledData,
      }));
    }
  }, [prefilledData]);

  useEffect(() => {
    if (viewOnly){
      return;
    }
    const prednisoneDuration = calculateDurationMonths(patient.prednisoneStartDate, new Date());
    const boneTherapyDurationMonths = calculateDurationMonths(patient.boneTherapyStartDate, new Date());
    setPatient((prev) => ({
      ...prev,
      prednisoneDuration: prednisoneDuration || '',
      boneTherapyDurationMonths: boneTherapyDurationMonths || '',
    }));
  // eslint-disable-next-line
  }, [patient.prednisoneStartDate, patient.boneTherapyStartDate]);

  const handleSubmit = async (e) => {
    if (viewOnly){
      return;
    }
    e.preventDefault();
    try {
      const response = await apiRequest(`/api/history/`, {
        method: 'POST',
        body: JSON.stringify(patient),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      showSnackbar('Patient history saved successfully.', 'success');
      navigate(`/patient/${patient.patientId}`);
    } catch (error) {
      console.error('Error saving patient history:', error);
      showSnackbar('Failed to save patient history.', 'error');
    }
  };

  const renderSection = (sectionKey) => {
    const fields = formFields[sectionKey].filter((field) => !field.condition || field.condition(patient));
    if (fields.length === 0) return null;

    return (
      <section key={sectionKey} className="form-section">
        <h3>{sectionKey.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}</h3>
        {fields.map((field) => (
          <RenderField
            key={field.name}
            field={field}
            patient={patient}
            setPatient={setPatient}
            viewOnly={viewOnly}
          />
        ))}
      </section>
    );
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => navigate(`/patient/${patient.patientId}`)}
        style={{ marginBottom: '20px' }}
      >
        Go Back
      </Button>
      <form onSubmit={handleSubmit} className="medical-worksheet-form">
        {Object.keys(formFields).map(renderSection)}
        {!viewOnly &&
        <Button type="submit" variant="contained" color="primary">
          Save Patient History
        </Button>
        }
      </form>
    </div>
  );
};

export default HistoryForm;