// worksheet-frontend/src/Pages/History/RenderField.js
import React from 'react';
import { Button } from '@mui/material';

const RenderField = ({ field, patient, setPatient, viewOnly }) => {

  const handleChange = (e) => {
    if (viewOnly){
      return;
    }
    const { name, value } = e.target;
    setPatient((prev) => ({ ...prev, [name]: value }));
  };

  const handleTableChange = (tableName, index, field, value) => {
    if (viewOnly){
      return;
    }
    const updatedTable = [...patient[tableName]];
    updatedTable[index][field] = value;
    setPatient((prev) => ({ ...prev, [tableName]: updatedTable }));
  };

  const addTableRow = (table, defaultRow) => {
    if (viewOnly){
      return;
    }
    setPatient((prev) => ({
      ...prev,
      [table]: [
        ...(prev[table] ?? []),
        defaultRow,
      ],
    }));
  };

  const removeTableRow = (table, index) => {
    if (viewOnly){
      return;
    }
    const updatedTable = patient[table].filter((_, i) => i !== index);
    setPatient((prev) => ({ ...prev, [table]: updatedTable }));
  };

  const handleBulletChange = (e) => {
    if (viewOnly) {
      return;
    }
    const value = e.target.value;
    const lines = value.split('\n').map(line => {
      // Remove bullet point if the line starts with it and is empty after trimming
      if (line.startsWith('• ') && line.trim() === '•') {
        return '';
      }
      return line.startsWith('• ') ? line : `• ${line.trim()}`;
    });
    setPatient((prev) => ({ ...prev, [field.name]: lines.join('\n') }));
  };

  if (!field.condition || field.condition(patient)) {
    switch (field.type) {
      case 'text':
      case 'date':
      case 'month':
      case 'number':
        return (
          <div key={field.name} className={`form-group ${field.colSize}`}>
            <label>{field.label}{field.patientLabel && <div className={"note"}>({field.patientLabel})</div>}</label>
            {field.note && <div className={"note"}>{field.note}</div>}
            <input
              type={field.type}
              name={field.name}
              value={patient[field.name]}
              onChange={handleChange}
              readOnly={viewOnly || field.readOnly}
              required={field.required || false}
              step={field.step}
            />
          </div>
        );
      case 'radio':
        return (
          <div key={field.name} className={`form-group ${field.colSize}`}>
            <label>{field.label}{field.patientLabel && <div className={"note"}>({field.patientLabel})</div>}</label>
            {field.note && <div className={"note"}>{field.note}</div>}
            {field.options.map((option) => (
              <label key={option}>
                <input
                  type="radio"
                  name={field.name}
                  value={option}
                  checked={patient[field.name] === option}
                  onChange={handleChange}
                  disabled={viewOnly || field.readOnly}
                  required={field.required || false}
                />
                {option}
              </label>
            ))}
          </div>
        );
      case 'table':
        return (
          <div key={field.name} className={`form-group ${field.colSize}`}>
            <label>{field.label}{field.patientLabel && <div className={"note"}>({field.patientLabel})</div>}</label>
            {field.note && <div className={"note"}>{field.note}</div>}
            <table className="fracture-table">
              <thead>
                <tr>
                  {field.fields?.map((subField) => (
                    <th key={subField.name}>{subField.label}</th>
                  ))}
                  {!viewOnly && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {patient.fractureDetails?.map((fracture, index) => (
                  <tr key={index}>
                    {field.fields?.map((subField, index_secondary) => (
                      <td key={index_secondary}>
                        {subField.type === 'select' ? (
                          <select
                            name={subField.name}
                            value={fracture[subField.name]}
                            onChange={(e) =>
                              handleTableChange(field.name, index, subField.name, e.target.value)
                            }
                            disabled={viewOnly || subField.readOnly}
                          >
                            {subField.options?.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type={subField.type}
                            name={subField.name}
                            value={fracture[subField.name]}
                            onChange={(e) =>
                              handleTableChange(field.name, index, subField.name, e.target.value)
                            }
                            disabled={viewOnly || subField.readOnly}
                          />
                        )}
                      </td>
                    ))}
                    {!viewOnly && (
                      <td>
                        <Button
                          type="button"
                          variant="contained"
                          onClick={() => removeTableRow(field.name, index)}
                        >
                          Remove
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {!viewOnly && (
              <Button type="button" onClick={() => addTableRow(field.name, field.fields.reduce((acc, curr) => {
                acc[curr.name] = '';
                return acc;
              }, {}))} variant="contained">
                Add Fracture
              </Button>
            )}
          </div>
        );
      case 'select':
        return (
          <div key={field.name} className={`form-group ${field.colSize}`}>
            <label>{field.label}{field.patientLabel && <div className={"note"}>({field.patientLabel})</div>}</label>
            {field.note && <div className={"note"}>{field.note}</div>}
            <select
              name={field.name}
              value={patient[field.name]}
              onChange={handleChange}
              disabled={viewOnly || field.readOnly}
              required={field.required || false}
            >
              <option value="">Select an option</option>
              {field.options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        );
      case 'textarea':
        return (
          <div key={field.name} className={`form-group ${field.colSize}`}>
            <label>{field.label}{field.patientLabel && <div className={"note"}>({field.patientLabel})</div>}</label>
            {field.note && <div className={"note"}>{field.note}</div>}
            <textarea
              name={field.name}
              value={patient[field.name]}
              onChange={handleBulletChange}
              readOnly={viewOnly || field.readOnly}
              required={field.required || false}
              rows={field.rows || 5}
              style={{ paddingLeft: '20px', whiteSpace: 'pre-wrap' }}
            />
          </div>
        );
      default:
        return null;
    }
  }
};

export default RenderField;

