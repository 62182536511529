import React from 'react';

const ExplanationReport = ({ selectedReport }) => {
  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Report</h2>
      <div style={styles.sectioMain}>
        <div style={styles.section}>
          <h3 style={styles.subHeader}>REFERENCE EXAMINATIONS:</h3>
          <p style={styles.text}>{selectedReport["BMD Reference Examination"]}</p>
        </div>
        <div style={styles.section}>
          <h3 style={styles.subHeader}>TECHNIQUE:</h3>
          <p style={styles.text}>{selectedReport["BMD Technique"]}</p>
        </div>
        <div style={styles.section}>
          <h3 style={styles.subHeader}>FINDINGS:</h3>
          <p style={styles.text}>{selectedReport["BMD Findings"]}</p>
          <p style={styles.text}>BONE MINERAL DENSITY: {selectedReport["BMD Diagnostic Category"]}</p>
          <p style={styles.text}>10 YEAR ABSOLUTE FRACTURE RISK: {selectedReport["BMD Fracture Risk"]}</p>
          <p style={styles.text}>RISK MODIFIERS: {selectedReport["BMD Risk Modifiers"]}</p>
        </div>
        <div style={styles.section}>
          <h3 style={styles.subHeader}>SUMMARY:</h3>
          <p style={styles.text}>{selectedReport["BMD Summary Overall Statement"]}</p>
          <p style={styles.text}>{selectedReport["BMD Summary Recommendations"]}</p>
          <p style={styles.text}>{selectedReport["BMD Summary Change Statement"]}</p>
          <p style={styles.text}>{selectedReport["BMD Summary Follow Up Statement"]}</p>
          <p style={styles.text}>CAROC recommendations (2010) age 50 years and older:</p>
          <p style={styles.text}>T-score between -1 and -2.5 = low bone mass</p>
          <p style={styles.text}>T-score -2.5 or less = osteoporosis</p>
          <p style={styles.text}>Fragility fractures of spine or hip or 2 fragility fractures elsewhere = osteoporosis and high fracture risk regardless of T-score</p>
          <p style={styles.text}>Bisphosphonate therapy may lower fracture risk.</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "'Arial', sans-serif",
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  sectionMain: {
    marginBottom: '20px',
    padding: '10px',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  subHeader: {
    color: '#0056b3',
    marginBottom: '10px',
    textAlign: 'left'
  },
  text: {
    whiteSpace: 'pre-line',
    color: '#333',
  },
  textBlock: {
    marginTop: '10px',
    color: '#333',
  },
  list: {
    margin: 0,
    paddingLeft: '20px',
  },
};

export default ExplanationReport;