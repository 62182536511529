import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Report from './Report';
import { apiRequest } from '../../util';

const ReportPage = () => {
  const { study_uid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState(false);
  
  const fetchReportByUID = async (study_uid) => {
    try {
      setIsLoading(true);
      const response = await apiRequest(`/api/report/${study_uid}`);
      const data = await response.json();
      if (response.ok) {
        setSelectedReport(data);
        setIsLoading(false);
      } else {
        console.error('Error fetching report:', data.error);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching report:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (study_uid) {
        fetchReportByUID(study_uid);
    }
    // eslint-disable-next-line
  }, [study_uid]);

  if (isLoading) return <div>Loading...</div>;

  if (!selectedReport) return <div>Selected report not found.</div>;

  return (
  <div>
    <Report selectedReport={selectedReport}/>
  </div>
  )
};

export default ReportPage;