import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MedicalWorksheetForm from './MedicalWorksheetForm';
import { apiRequest } from '../../util';

const ViewWorksheetPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorksheet, setSelectedWorksheet] = useState(false);

  
  const fetchWorksheetById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiRequest(`/api/worksheet/${id}`);
      const data = await response.json();
      if (response.ok) {
        setSelectedWorksheet({
          patientId: data.patient.id,
          name: data.patient.name || '',
          birthDate: data.patient.birth_date || '',
          mrn: data.patient.mrn || '',
          gender: mapGender(data.patient.sex) || '',
          currentExamDate: data.date_time?.split('T')[0] || '',
          currentExamLocation: data.institution_name ? data.institution_name: '',
          previousExamLocation: data.previous_institution_name ? data.previous_institution_name: '',
          age: data.patient.age || '',
          height: data.height || '',
          weight: data.weight || '',
          accession: data.accession || '',
          ...data,
        });
        setIsLoading(false);
      } else {
        console.error('Error fetching study:', data.error);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching study:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWorksheetById(id);
    // eslint-disable-next-line
  }, [id]);
  
  const mapGender = (gender) => {
    switch (gender) {
    case 'female':
        return 'Female'
    case 'male':
        return 'Male'
    default:
        return 'Other'
    }
  };

  if (isLoading) return <div>Loading...</div>;

  if (!selectedWorksheet) return <div>Selected worksheet not found.</div>;

  return (
  <>
    <MedicalWorksheetForm prefilledData={selectedWorksheet} viewOnly={true}/>
  </>
  )
};

export default ViewWorksheetPage;