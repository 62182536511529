import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HistoryForm from './HistoryForm';
import { apiRequest } from '../../util';

const HistoryPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [patientHistory, setPatientHistory] = useState(null);

  const fetchHistoryById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiRequest(`/api/history/${id}`);
      const data = await response.json();

      if (response.ok) {
        setPatientHistory({
          patientId: data.patient_id,
          name: data.name || '',
          birthDate: data.birth_date || '',
          mrn: data.mrn || '',
          age: data.age || '',
          gender: mapGender(data.gender) || '',
          technologistName: data.technologistName || '',
          menopausalStatus: data.menopausalStatus || '',
          ageAtMenopause: data.ageAtMenopause || '',
          prednisoneUsage: data.prednisoneUsage || '',
          prednisoneDosage: data.prednisoneDosage || '',
          prednisoneStartDate: data.prednisoneStartDate || '',
          prednisoneDuration: data.prednisoneDuration || '',
          fractureHistory: data.fractureHistory || '',
          fractureDetails: data.fractureDetails || [],
          boneTherapyUsage: data.boneTherapyUsage || '',
          boneTherapyName: data.boneTherapyName || '',
          boneTherapyStartDate: data.boneTherapyStartDate || '',
          boneTherapyDurationMonths: data.boneTherapyDurationMonths || '',
        });
      } else {
        console.error('Error fetching patient history:', data.error);
      }
    } catch (error) {
      console.error('Error fetching patient history:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHistoryById(id);
    // eslint-disable-next-line
  }, [id]);

  const mapGender = (gender) => {
    switch (gender) {
      case 'female':
        return 'Female';
      case 'male':
        return 'Male';
      default:
        return 'Other';
    }
  };

  if (isLoading) return <div>Loading...</div>;

  if (!patientHistory) return <div>Patient history not found.</div>;

  return (
  <>
    <HistoryForm prefilledData={patientHistory} viewOnly={true}/>
  </>
  )
};

export default HistoryPage;