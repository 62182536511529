import React, { createContext, useState, useContext } from 'react';
import { Snackbar, Alert } from '@mui/material';

const SnackbarContext = createContext();

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [autoHide, setAutoHide] = useState(true);

  const showSnackbar = (message, severity = 'success', autoHide = true) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setAutoHide(autoHide);
    setSnackbarOpen(true);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      {autoHide ? 
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      : 
      (
        <Alert onClose={handleClose} severity={snackbarSeverity} sx={{position: 'relative', top: 0, width: '100%'}}>
          {snackbarMessage}
        </Alert>
      )}
    </SnackbarContext.Provider>
  );
};