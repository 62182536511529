import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../SnackbarContext';
import { apiRequest } from '../../util';

const CreatePatientForm = () => {
  const [mrn, setMrn] = useState('');
  const [gender, setGender] = useState('');
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation for MRN length
    if (mrn.length !== 10) {
      showSnackbar('MRN must be exactly 10 characters long.', 'error');
      return;
    }
    try {
      const response = await apiRequest(`/api/patient/`, {
        method: 'POST',
        body: JSON.stringify({ mrn, gender }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      showSnackbar('Patient created successfully.', 'success');
      // Navigate to the patient page using the patient ID
      navigate(`/patient/${data.patientId}`);
    } catch (error) {
      console.error('Error creating patient:', error);
      showSnackbar('Failed to create patient.', 'error');
    }
  };

  return (
    <>
        <form onSubmit={handleSubmit} className="medical-worksheet-form">
          <h3>Create Patient</h3>
          <section className="form-section">
            <div className="form-group col-3">
              <label>MRN:</label>
              <input
                type="text"
                name="mrn"
                value={mrn}
                onChange={(e) => setMrn(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-3">
              <label>Gender:</label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="Male"
                  checked={gender === 'Male'}
                  onChange={(e) => setGender(e.target.value)}
                  required
                />
                Male
              </label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="Female"
                  checked={gender === 'Female'}
                  onChange={(e) => setGender(e.target.value)}
                  required
                />
                Female
              </label>
            </div>
          </section>

          <Button type="submit" variant="contained" color="primary">
            Create Patient
          </Button>
        </form>
    </>
  );
};

export default CreatePatientForm;